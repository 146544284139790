(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expertisesHandler = void 0;
var _mobile = require("../modules/mobile");
// import { a11ySliders } from './slider';

// document.addEventListener('DOMContentLoaded', function(event) {

//     a11ySliders.init();

// })

var expertisesHandler = exports.expertisesHandler = {
  init: function init() {
    var $expertises = document.querySelector('.section-expertises');
    if (!$expertises) {
      return;
    }
    expertisesHandler.toggleCards();
    window.addEventListener("resize", function () {
      expertisesHandler.toggleCards();
    });
  },
  toggleCards: function toggleCards() {
    // let mobileBP = mobileBreakpoint();
    var vw = (0, _mobile.getViewportWidth)();
    var $expertises = document.querySelector('.section-expertises');
    var $expertisesLists = document.querySelectorAll('.expertises-list');
    // console.log(vw >= mobileBP, $expertisesLists.length === 0)

    var $cards = $expertises.querySelectorAll('.krs-mobile-slider .swiper-slide .card-expertise');
    var arrayCards = [];
    Array.prototype.forEach.call($cards, function ($card, i) {
      arrayCards[i] = $card.cloneNode(true);
    });
    // console.log($cards, arrayCards);

    if (vw >= 1080) {
      expertisesHandler.setDesktopCards(arrayCards);
    } else {
      Array.prototype.forEach.call($expertisesLists, function ($expertisesList, i) {
        $expertisesList.remove();
      });
    }
  },
  setDesktopCards: function setDesktopCards(arrayCards) {
    var $expertisesLists = document.querySelectorAll('.expertises-list');
    if ($expertisesLists.length !== 0) {
      // Refresh gutenberg component
      if (window.acf) {
        Array.prototype.forEach.call($expertisesLists, function ($expertisesList, i) {
          $expertisesList.remove();
        });
      } else {
        return;
      }
    }
    var $expertisesWrap = document.querySelector('.section-expertises .section__content');
    var middleIndex = Math.ceil(arrayCards.length / 2);
    var firstHalf = arrayCards.splice(0, middleIndex);
    var secondHalf = arrayCards.splice(-middleIndex);
    var $expertisesList = document.createElement('div');
    $expertisesList.classList.add('expertises-list');
    var $expertisesList2 = document.createElement('div');
    $expertisesList2.classList.add('expertises-list');
    for (var index = 0; index < firstHalf.length; index++) {
      var card = firstHalf[index];
      $expertisesList.appendChild(card);
    }
    for (var _index = 0; _index < secondHalf.length; _index++) {
      var _card = secondHalf[_index];
      $expertisesList2.appendChild(_card);
    }
    $expertisesWrap.appendChild($expertisesList);
    $expertisesWrap.appendChild($expertisesList2);
  }
};

},{"../modules/mobile":2}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getViewportWidth = getViewportWidth;
exports.headerBreakpoint = headerBreakpoint;
exports.mobileBreakpoint = mobileBreakpoint;
exports.mobileHandler = void 0;
var bodyScrollLock = require('body-scroll-lock');
var disableBodyScroll = bodyScrollLock.disableBodyScroll;
var enableBodyScroll = bodyScrollLock.enableBodyScroll;
function mobileBreakpoint() {
  return 768;
}
function headerBreakpoint() {
  return 1024;
}
var mobileHandler = exports.mobileHandler = {
  init: function init() {
    mobileHandler.isMobile();
    mobileHandler.setRealVH();
    mobileHandler.mobileNav();
    // mobileHandler.ux();

    window.addEventListener('resize', function () {
      mobileHandler.isMobile();
      mobileHandler.setRealVH();
      // clearTimeout(mobileNav);
      // mobileNav = setTimeout(mobileHandler.mobileNav(), 500);
      // mobileHandler.ux();
    });

    // mobileHandler.debug();
  },
  isMobile: function isMobile() {
    var isMobile = getViewportWidth() < mobileBreakpoint();
    if (isMobile) {
      document.body.classList.add('mobile');
    } else {
      document.body.classList.remove('mobile');
    }
  },
  setRealVH: function setRealVH() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    var vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
  },
  mobileNav: function mobileNav() {
    var isMobile = getViewportWidth() < headerBreakpoint();
    var $burger = document.querySelector('.btn--toggle-menu');
    if ($burger) {
      var $menuParentWrap = document.querySelector('.header--main-wrap');
      var $navMain = $menuParentWrap.querySelector('.nav--main .menu-wrap > ul');
      document.addEventListener('click', function (e) {
        if (e.target === $burger) {
          $burger.classList.toggle('opened');
          $menuParentWrap.classList.toggle('opened');
          mobileHandler.closeAllSubmenus();
          if ($menuParentWrap.classList.contains('opened')) {
            $burger.classList.remove('closed');
            disableBodyScroll($navMain);
          } else {
            $burger.classList.add('closed');
            enableBodyScroll($navMain);
          }
        }
      });
    }

    /** MANAGE SUBMENU **/
    var $anchorSubmenus = document.querySelectorAll('.has-submenu > a, .has-megamenu > a');
    if ($burger && $anchorSubmenus) {
      Array.prototype.forEach.call($anchorSubmenus, function ($anchorSubmenu) {
        if (isMobile) {
          $anchorSubmenu.addEventListener('click', mobileHandler.toggleSubmenu);
        } else {
          $anchorSubmenu.removeEventListener('click', mobileHandler.toggleSubmenu);
        }
      });

      // Auto close submenus when clicking outside
      var $navContent = document.querySelector('.menu-wrap li.has-submenu, .menu-wrap li.has-megamenu');
      if ($navContent) {
        document.addEventListener('click', function (el) {
          if (el.target !== $navContent && !$navContent.contains(el.target)) {
            var target = el.target.closest('a');
            mobileHandler.closeAllSubmenus(target);
          }
        });
      }
    }

    /** MANAGE MEGAMENU **/
    var $anchorMegamenus = document.querySelectorAll('.megamenu-section > a');
    if ($burger && $anchorMegamenus) {
      Array.prototype.forEach.call($anchorMegamenus, function ($anchorMegamenu) {
        if (isMobile) {
          $anchorMegamenu.addEventListener('click', mobileHandler.toggleMegamenu);
        } else {
          $anchorMegamenu.removeEventListener('click', mobileHandler.toggleMegamenu);
        }
      });

      // Auto close submenus when clicking outside
      // let $navContent = document.querySelector('.menu-wrap li.has-submenu, .menu-wrap li.has-megamenu');
      // if ($navContent) {
      //     document.addEventListener('click', (el) => {
      //         if (el.target !== $navContent && !$navContent.contains(el.target)) {
      //             let target = el.target.closest('a');
      //             mobileHandler.closeAllSubmenus(target);
      //         }
      //     });
      // }
    }
  },
  toggleSubmenu: function toggleSubmenu(el) {
    el.preventDefault();
    var target = el.target.closest('a');

    // console.log("submenu "+target);

    if (target.matches('.submenu-parent, .megamenu-parent') && getViewportWidth() < headerBreakpoint()) {
      mobileHandler.closeAllSubmenus(target);
      var $parentToToggle = target.closest('li.has-submenu, li.has-megamenu');
      var $parentIsOpened = $parentToToggle.classList.contains('expanded') ? true : false;
      if (!$parentIsOpened || $parentIsOpened && target.getAttribute('href') === '#') {
        $parentToToggle.classList.toggle('expanded');
      } else {
        window.location = target.href;
      }
    }
  },
  toggleMegamenu: function toggleMegamenu(el) {
    el.preventDefault();
    // console.log(el, el.target, el.target.closest('.megamenu-section'));
    var target = el.target.closest('a');
    if (target.closest('.megamenu-section') && getViewportWidth() < headerBreakpoint()) {
      mobileHandler.closeAllMegamenus(target);
      var $parentToToggle = target.closest('.megamenu-section');
      var $parentIsOpened = $parentToToggle.classList.contains('expanded') ? true : false;
      if (!$parentIsOpened || $parentIsOpened && target.getAttribute('href') === '#') {
        $parentToToggle.classList.toggle('expanded');
      } else {
        window.location = target.href;
      }
    }
  },
  closeAllSubmenus: function closeAllSubmenus(target) {
    var $submenus = document.querySelectorAll('li.has-submenu, li.has-megamenu');
    if (target) {
      // console.log(target.closest('.megamenu-section'));
      // let selectors = target.closest('.megamenu-section') ? target.closest('li.has-megamenu .megamenu-section') : target.closest('li.has-submenu, li.has-megamenu');
      $submenus = Array.from($submenus).filter(function (node) {
        return node !== target.closest('li.has-submenu, li.has-megamenu');
      });
    }
    if ($submenus) {
      Array.prototype.forEach.call($submenus, function ($submenu) {
        $submenu.classList.remove('expanded');
      });
    }
  },
  closeAllMegamenus: function closeAllMegamenus(target) {
    var $megamenus = document.querySelectorAll('.megamenu-section');
    if (target) {
      $megamenus = Array.from($megamenus).filter(function (node) {
        return node !== target.closest('.megamenu-section');
      });
    }
    if ($megamenus) {
      Array.prototype.forEach.call($megamenus, function ($megamenu) {
        $megamenu.classList.remove('expanded');
      });
    }
  },
  ux: function ux() {},
  debug: function debug() {
    var vw = getViewportWidth();
    console.log('vw: ' + vw);
    window.addEventListener('resize', function () {
      vw = getViewportWidth();
      console.log('vw: ' + vw);
    });
  }
};
function getViewportWidth() {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  return viewportWidth;
}

},{"body-scroll-lock":3}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enableBodyScroll = exports.disableBodyScroll = exports.clearAllBodyScrollLocks = void 0;
function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

// Older browsers don't support event options, feature detect it.

// Adopted and modified solution from Bohdan Didukh (2017)
// https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-posi

var hasPassiveEvents = false;
if (typeof window !== 'undefined') {
  var passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true;
      return undefined;
    }
  };
  window.addEventListener('testPassive', null, passiveTestOptions);
  window.removeEventListener('testPassive', null, passiveTestOptions);
}
var isIosDevice = typeof window !== 'undefined' && window.navigator && window.navigator.platform && (/iP(ad|hone|od)/.test(window.navigator.platform) || window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1);
var locks = [];
var documentListenerAdded = false;
var initialClientY = -1;
var previousBodyOverflowSetting = void 0;
var previousBodyPosition = void 0;
var previousBodyPaddingRight = void 0;

// returns true if `el` should be allowed to receive touchmove events.
var allowTouchMove = function allowTouchMove(el) {
  return locks.some(function (lock) {
    if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
      return true;
    }
    return false;
  });
};
var preventDefault = function preventDefault(rawEvent) {
  var e = rawEvent || window.event;

  // For the case whereby consumers adds a touchmove event listener to document.
  // Recall that we do document.addEventListener('touchmove', preventDefault, { passive: false })
  // in disableBodyScroll - so if we provide this opportunity to allowTouchMove, then
  // the touchmove event on document will break.
  if (allowTouchMove(e.target)) {
    return true;
  }

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom).
  if (e.touches.length > 1) return true;
  if (e.preventDefault) e.preventDefault();
  return false;
};
var setOverflowHidden = function setOverflowHidden(options) {
  // If previousBodyPaddingRight is already set, don't set it again.
  if (previousBodyPaddingRight === undefined) {
    var _reserveScrollBarGap = !!options && options.reserveScrollBarGap === true;
    var scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
    if (_reserveScrollBarGap && scrollBarGap > 0) {
      var computedBodyPaddingRight = parseInt(window.getComputedStyle(document.body).getPropertyValue('padding-right'), 10);
      previousBodyPaddingRight = document.body.style.paddingRight;
      document.body.style.paddingRight = computedBodyPaddingRight + scrollBarGap + 'px';
    }
  }

  // If previousBodyOverflowSetting is already set, don't set it again.
  if (previousBodyOverflowSetting === undefined) {
    previousBodyOverflowSetting = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
  }
};
var restoreOverflowSetting = function restoreOverflowSetting() {
  if (previousBodyPaddingRight !== undefined) {
    document.body.style.paddingRight = previousBodyPaddingRight;

    // Restore previousBodyPaddingRight to undefined so setOverflowHidden knows it
    // can be set again.
    previousBodyPaddingRight = undefined;
  }
  if (previousBodyOverflowSetting !== undefined) {
    document.body.style.overflow = previousBodyOverflowSetting;

    // Restore previousBodyOverflowSetting to undefined
    // so setOverflowHidden knows it can be set again.
    previousBodyOverflowSetting = undefined;
  }
};
var setPositionFixed = function setPositionFixed() {
  return window.requestAnimationFrame(function () {
    // If previousBodyPosition is already set, don't set it again.
    if (previousBodyPosition === undefined) {
      previousBodyPosition = {
        position: document.body.style.position,
        top: document.body.style.top,
        left: document.body.style.left
      };

      // Update the dom inside an animation frame 
      var _window = window,
        scrollY = _window.scrollY,
        scrollX = _window.scrollX,
        innerHeight = _window.innerHeight;
      document.body.style.position = 'fixed';
      document.body.style.top = -scrollY;
      document.body.style.left = -scrollX;
      setTimeout(function () {
        return window.requestAnimationFrame(function () {
          // Attempt to check if the bottom bar appeared due to the position change
          var bottomBarHeight = innerHeight - window.innerHeight;
          if (bottomBarHeight && scrollY >= innerHeight) {
            // Move the content further up so that the bottom bar doesn't hide it
            document.body.style.top = -(scrollY + bottomBarHeight);
          }
        });
      }, 300);
    }
  });
};
var restorePositionSetting = function restorePositionSetting() {
  if (previousBodyPosition !== undefined) {
    // Convert the position from "px" to Int
    var y = -parseInt(document.body.style.top, 10);
    var x = -parseInt(document.body.style.left, 10);

    // Restore styles
    document.body.style.position = previousBodyPosition.position;
    document.body.style.top = previousBodyPosition.top;
    document.body.style.left = previousBodyPosition.left;

    // Restore scroll
    window.scrollTo(x, y);
    previousBodyPosition = undefined;
  }
};

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
var isTargetElementTotallyScrolled = function isTargetElementTotallyScrolled(targetElement) {
  return targetElement ? targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight : false;
};
var handleScroll = function handleScroll(event, targetElement) {
  var clientY = event.targetTouches[0].clientY - initialClientY;
  if (allowTouchMove(event.target)) {
    return false;
  }
  if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
    // element is at the top of its scroll.
    return preventDefault(event);
  }
  if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
    // element is at the bottom of its scroll.
    return preventDefault(event);
  }
  event.stopPropagation();
  return true;
};
var disableBodyScroll = exports.disableBodyScroll = function disableBodyScroll(targetElement, options) {
  // targetElement must be provided
  if (!targetElement) {
    // eslint-disable-next-line no-console
    console.error('disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.');
    return;
  }

  // disableBodyScroll must not have been called on this targetElement before
  if (locks.some(function (lock) {
    return lock.targetElement === targetElement;
  })) {
    return;
  }
  var lock = {
    targetElement: targetElement,
    options: options || {}
  };
  locks = [].concat(_toConsumableArray(locks), [lock]);
  if (isIosDevice) {
    setPositionFixed();
  } else {
    setOverflowHidden(options);
  }
  if (isIosDevice) {
    targetElement.ontouchstart = function (event) {
      if (event.targetTouches.length === 1) {
        // detect single touch.
        initialClientY = event.targetTouches[0].clientY;
      }
    };
    targetElement.ontouchmove = function (event) {
      if (event.targetTouches.length === 1) {
        // detect single touch.
        handleScroll(event, targetElement);
      }
    };
    if (!documentListenerAdded) {
      document.addEventListener('touchmove', preventDefault, hasPassiveEvents ? {
        passive: false
      } : undefined);
      documentListenerAdded = true;
    }
  }
};
var clearAllBodyScrollLocks = exports.clearAllBodyScrollLocks = function clearAllBodyScrollLocks() {
  if (isIosDevice) {
    // Clear all locks ontouchstart/ontouchmove handlers, and the references.
    locks.forEach(function (lock) {
      lock.targetElement.ontouchstart = null;
      lock.targetElement.ontouchmove = null;
    });
    if (documentListenerAdded) {
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? {
        passive: false
      } : undefined);
      documentListenerAdded = false;
    }

    // Reset initial clientY.
    initialClientY = -1;
  }
  if (isIosDevice) {
    restorePositionSetting();
  } else {
    restoreOverflowSetting();
  }
  locks = [];
};
var enableBodyScroll = exports.enableBodyScroll = function enableBodyScroll(targetElement) {
  if (!targetElement) {
    // eslint-disable-next-line no-console
    console.error('enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.');
    return;
  }
  locks = locks.filter(function (lock) {
    return lock.targetElement !== targetElement;
  });
  if (isIosDevice) {
    targetElement.ontouchstart = null;
    targetElement.ontouchmove = null;
    if (documentListenerAdded && locks.length === 0) {
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? {
        passive: false
      } : undefined);
      documentListenerAdded = false;
    }
  }
  if (isIosDevice) {
    restorePositionSetting();
  } else {
    restoreOverflowSetting();
  }
};

},{}],4:[function(require,module,exports){
"use strict";

var _expertises = require("../../../../../_src/js/components/expertises");
if (window.acf) {
  window.acf.addAction('render_block_preview/type=expertises', function (response, $el, data) {
    _expertises.expertisesHandler.init();
  });
}
document.addEventListener('DOMContentLoaded', function (event) {
  _expertises.expertisesHandler.init();
});

},{"../../../../../_src/js/components/expertises":1}]},{},[4]);
